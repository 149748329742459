import { Add, Autorenew, GridView, TableRows } from '@mui/icons-material';
import { Button, Grid, Tooltip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorAlert, SuccessAlert, InfoAlert } from '../components/Alerts';
import { getNetworkApi } from '../components/keycloak';
import {
	CertificateMetaData,
	MailclientConfig,
	Tenant,
} from '../generated';
import { trackPromise } from 'react-promise-tracker';
import React from 'react';
import {
	isAuthorizedForGlobalAdminRole,
} from '../components/format';
import TenantsTableView from '../components/TenantsTableView';
import TenantsGridView from '../components/TenantsGridView';
import TenantForm from '../components/TenantForm';
import MailclientConfigForm from '../components/MailclientConfigForm';
import { useHistory } from 'react-router-dom';
import Confirm from '../components/Confirm';

const Tenants = () => {
	const [userRoleList, setUserRoleList] = React.useState([] as string[]);
	let history = useHistory();

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const [selectedMailclientTenant, setSelectedMailclientTenant] = useState({} as Tenant);
	const [isNewTenant, setIsNewTenant] = useState(false);
	const [isNewMailclientConfig, setIsNewMailclientConfig] = useState(false);
	const [refresh, setRefresh] = useState(0);
	const [displayTenant, setDisplayTenant] = useState(false);
	const [showTenantForm, setShowTenantForm] = useState(false);
	const [showMailclientConfigForm, setShowMailclientConfigForm] = useState(false);
	const [selectedTenant, setSelectedTenant] = useState({} as Tenant);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [showInfoAlert, setShowInfoAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [tenantList, setTenantList] = useState([] as Tenant[]);
	const [showConfirmCertificateRevocation, setShowConfirmCertificateRevocation] = useState(false);
	const [selectedTenantForCertificateRevocation, setSelectedTenantForCertificateRevocation] = useState({} as Tenant);
	const [selectedTenantForDeletion, setSelectedTenantForDeletion] = useState({} as Tenant);


	const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
	const [deleteText, setDeleteText] = useState('');

	const [selectedMailclientConfig, setSelectedMailclientConfig] = useState({} as MailclientConfig);
	const isAuthorizedGlobalAdmin: boolean = useMemo(() => {
		return isAuthorizedForGlobalAdminRole(userRoleList);
	}, [userRoleList]);

	const forwardToUsers = useCallback(
		(tableData: any) => {
			history.push({
				pathname: '/tenantId=' + tableData.tenantIdentifier + '/users',
			});
		},
		[history]
	);
	const forwardToResidences = useCallback(
		(tenant: Tenant) => {
			history.push({
				pathname: '/tenantId=' + tenant.tenantIdentifier + '/residences',
			});
		},
		[history]
	);

	useEffect(() => {
		const loadTenants = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAuthorizedTenants();
				setTenantList(result);
			} catch (error: any) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Tenants konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Tenants konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadTenants());
	}, [showSuccessAlert, refresh]);


	const handleClickOpenTenantsForm = (isNewTenant: boolean, tenant: Tenant) => {
		setSelectedTenant({});
		if (!isNewTenant) {
			setSelectedTenant(tenant);
		}
		setIsNewTenant(isNewTenant);
		setShowTenantForm(true);
		setDisplayTenant(false);
	};

	const handleClickOpenMailclientConfigForm = async (tenant: Tenant) => {
		setSelectedMailclientConfig(await loadMailclientConfig(tenant))
		setSelectedMailclientTenant(tenant)
		setShowMailclientConfigForm(true);
	};

	const handleDisplayTenant = (tenant: Tenant) => {
		setDisplayTenant(true);
		setShowTenantForm(true);
		setSelectedTenant(tenant);
		setIsNewTenant(false);
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	const [isTableViewSelected, setIsTableViewSelected] = React.useState(false);
	const handleSwitchChange = () => {
		setIsTableViewSelected(!isTableViewSelected);
	};

	const getCertificateFileName = (tenantIdentifier: string): string => {
		return tenantIdentifier + '.p12';
	};

	const loadCertificateFile = async (tenant: Tenant) => {
		const api = getNetworkApi();
		try {
			const result = await api.getCertificate(
				tenant.tenantIdentifier as string
			);
			return result;
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText(
				'Das ausgewählte Zertifikat konnte nicht aus der Datenbank geholt werden'
			);
			return null;
		}
	};

	const loadMailclientConfig = async (tenant: Tenant) => {
		const client = getNetworkApi();
		try{
			const result = await client.getMailclientConfig(
				tenant.tenantIdentifier as string
			);
			if (!(result.username === undefined)) {
				setIsNewMailclientConfig(false)
				return result
			} else {
				setIsNewMailclientConfig(true);
				return {} as MailclientConfig;
			}
		} catch (error) {
			setAlertText(
				'Die Emailserver Konfiguration konnte nicht aus der Datenbank geholt werden'
			);
		}
		return {} as MailclientConfig;
	};

	const loadCertificateMetaData = async (tenant: Tenant) => {
		const api = getNetworkApi();
		try {
			const result = await api.getCertificateMetaData(
				tenant.tenantIdentifier as string
			);
			return result;
		} catch (error) {
			setShowInfoAlert(true);
			setAlertText(
				'Für den Betreiber ' + tenant.tenantIdentifier + ' konnten keine gültigen Zertifikatsdaten aus der Datenbank geholt werden.'
			);
			return {} as CertificateMetaData;
		}
	};


	const handleDownloadCertificate = async (tenant: Tenant) => {
		let promiseForBlob = trackPromise(loadCertificateFile(tenant));
		let hyperlink = document.createElement('a');
		try {
			hyperlink.href = URL.createObjectURL(await promiseForBlob);
			hyperlink.download = getCertificateFileName(
				tenant.tenantIdentifier as string);
			hyperlink.click();
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText(
				'Das ausgewählte Zertifikat konnte nicht heruntergeladen werden'
			);
		}
	};

	const handleClickCertificateRevocation = (tenant: Tenant) => {
		setShowConfirmCertificateRevocation(true);
		setDeleteText('Wollen Sie wirklich das Zertifikat des Betreibers ' + tenant.tenantIdentifier + ' wiederrufen?');
		setSelectedTenantForCertificateRevocation(tenant);
	};

	const revokeCertificate = async () => {
		const client = getNetworkApi();
		try {
			await client.postCertificateRevocation(
				selectedTenantForCertificateRevocation.tenantIdentifier as string,
			);
			handleRefresh();
			setAlertText('Das Zertifikat des Betreibers wurde erfolgreich widerrufen');
			setShowSuccessAlert(true);
			setShowConfirmCertificateRevocation(false);
		} catch (error) {
			setAlertText('Das Zertifikat des Betreibers wurde nicht widerrufen');
			setShowErrorAlert(true);
		}
	};



	const renewCertificate = async (tenant: Tenant) => {
		const client = getNetworkApi();
		try {
			await client.postCertificate(
				tenant.tenantIdentifier as string,
			);
			handleRefresh();
			setAlertText('Das Zertifikat des Betreibers wurde erfolgreich erzeugt');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Das Zertifikat des Betreibers wurde nicht erzeugt');
			setShowErrorAlert(true);
		}
	};

	const handleClickDelete = (tenant: Tenant) => {
		setShowConfirmDeletion(true);
		setDeleteText('Wollen Sie wirklich den Betreiber ' + tenant.tenantIdentifier + ' löschen? Dies kann nicht mehr rückgängig gemacht werden. Die Daten sind für immer gelöscht.');
		setSelectedTenantForDeletion(tenant);
	};

	const deleteTenant = async () => {
		const client = getNetworkApi();
		try {
			await client.postCertificateRevocation(
				selectedTenantForDeletion.tenantIdentifier as string,
			);
			await client.postTenantDeletion(
				selectedTenantForDeletion.tenantIdentifier as string
			);
			setAlertText(
				'Betreiber ' + selectedTenantForDeletion.tenantIdentifier + ' wurde erfolgreich endgültig gelöscht.'
			);

			setShowConfirmDeletion(false);
			setSelectedTenantForDeletion({} as Tenant);
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Betreiber wurde nicht gelöscht.');
			setShowErrorAlert(true);
			setShowConfirmDeletion(false);
		}
	};


	return (
		<React.Fragment>
			<Grid
				container
				direction='column'
				justifyContent='space-between'
				alignItems='right'
				sx={{ padding: 3 }}>
				<Grid item xs={1} sx={{ mt: 2, marginBottom: 5 }}>
					<Button
						disabled={!isAuthorizedGlobalAdmin}
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleClickOpenTenantsForm(true, {})}>
						{<Add />} Betreiber
					</Button>
					<Button
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleRefresh()}>
						{<Autorenew />}
					</Button>
					<Tooltip title={isTableViewSelected ? 'Kärtchen' : 'Tabelle'}>
						<Button
							variant='contained'
							sx={{ float: 'right', mr: 5 }}
							onClick={() => handleSwitchChange()}>
							{isTableViewSelected ? <GridView /> : <TableRows />}
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					{isTableViewSelected ? (
						<TenantsTableView
							tenantsList={tenantList}
							handleClickOpenTenantForm={handleClickOpenTenantsForm}
							handleClickUsers={forwardToUsers}
							handleClickResidences={forwardToResidences}
							userRoleList={userRoleList}
						/>
					) : (
						<TenantsGridView
							tenantList={tenantList}
							handleDisplayTenant={handleDisplayTenant}
							handleClickUsers={forwardToUsers}
							handleClickResidences={forwardToResidences}
							handleClickCertificate={handleDownloadCertificate}
							handleClickCertificateRevocation={handleClickCertificateRevocation}
							handleClickCertificateRenewal={renewCertificate}
							loadCertificateMetaData={loadCertificateMetaData}
							handleClickDelete={handleClickDelete}
							handleClickMailclientConfig={handleClickOpenMailclientConfigForm}
							userRoleList={userRoleList}
							refresh={refresh}
						/>
					)}
				</Grid>
			</Grid>

			{showTenantForm && (
				<TenantForm
					open={showTenantForm}
					setOpen={setShowTenantForm}
					isNewTenant={isNewTenant}
					displayTenant={displayTenant}
					selectedTenant={selectedTenant}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					tenantList={tenantList}
				/>
			)}
			{showConfirmCertificateRevocation && (
				<Confirm
					open={showConfirmCertificateRevocation}
					setOpen={setShowConfirmCertificateRevocation}
					text={deleteText}
					executefct={revokeCertificate}
				/>
			)}
			{showConfirmDeletion && (
				<Confirm
					open={showConfirmDeletion}
					setOpen={setShowConfirmDeletion}
					text={deleteText}
					executefct={deleteTenant}
				/>
			)}
			{showMailclientConfigForm && (
				<MailclientConfigForm
					open={showMailclientConfigForm}
					setOpen={setShowMailclientConfigForm}
					selectedMailclientConfig={selectedMailclientConfig}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					selectedMailclientTenant={selectedMailclientTenant}
					isNewMailclientConfig={isNewMailclientConfig}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
			{showInfoAlert && (
				<InfoAlert
					text={alertText}
					open={showInfoAlert}
					setOpen={setShowInfoAlert}
				/>
			)}
		</React.Fragment>
	);
};

export default Tenants;
