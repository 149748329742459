import { useCallback } from 'react';
import MaterialTable, { Column } from '@material-table/core';

import { Residence } from '../generated';
import React from 'react';
import { grey } from '@mui/material/colors';
import { Tooltip, Button, Paper } from '@mui/material';
import { Chair, Edit } from '@mui/icons-material';
import { MaterialTableIcons } from './MaterialTableIcons';
import { formatDate, viewportHeight } from './format';

interface ResidenceTableViewProps {
	residenceList: Residence[];
	handleClickOpenResidenceForm: any;
	handleClickUnits: any;
	isAuthorizedTenantAdmin: boolean;
}

export default function ResidenceTableView({
	residenceList,
	handleClickOpenResidenceForm,
	handleClickUnits,
	isAuthorizedTenantAdmin,
}: ResidenceTableViewProps) {
	const columns: Column<Residence>[] = [
		{ title: 'Name', field: 'residenceIdentifier' },
		{ title: 'Beschreibung', field: 'description', width: '40%' },
		{ title: 'letzte Änderung durch', field: 'userLastChange' },
		{
			title: 'letzte Änderung am',
			field: 'lastChanged',
			defaultSort: 'asc',
			sorting: true,
			render: useCallback(
				(residence: Residence) => formatDate(residence.lastChanged),
				[]
			),
		},
		{
			sorting: false,
			render: useCallback(
				(residence: Residence) => {
					return (
						<React.Fragment>
							<Tooltip title={isAuthorizedTenantAdmin ? 'Bearbeite Wohnheim' : ''}>
								<span>
									<Button
										disabled={!isAuthorizedTenantAdmin}
										onClick={() =>
											handleClickOpenResidenceForm(false, residence)
										}>
										<Edit color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
							<Tooltip
								title={isAuthorizedTenantAdmin ? 'Wohnbereiche anzeigen/hinzufügen' : ''}>
								<span>
									<Button
										disabled={!isAuthorizedTenantAdmin}
										onClick={() => handleClickUnits(residence)}>
										<Chair color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[isAuthorizedTenantAdmin]
			),
		},
	];

	return (
		<MaterialTable
			localization={{
				body: {
					emptyDataSourceMessage: 'Keine Betreiber hinterlegt.',
				},
			}}
			style={{
				borderRadius: '30px',
				fontSize: '12',
				color: grey[500],
			}}
			icons={MaterialTableIcons()}
			columns={columns}
			data={residenceList}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
			}}
			options={{
				paging: false,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: {
					fontSize: 'subtitle2',
					overflowWrap: 'break-word',
				},
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
