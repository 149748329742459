import React, { useCallback, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	Grid,
	ThemeProvider,
	useMediaQuery,
	useTheme,
	DialogContentText,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import { Save, Close } from '@mui/icons-material';
import { getNetworkApi } from '../components/keycloak';
import { getUserNameWithTenantSuffix, viewportHeight } from './format';
import { User, UserInsertParameters } from '../generated';
import MaterialTable, { Column, MTableToolbar } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { grey } from '@mui/material/colors';

interface UsersFromFileFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	usersFromFile: User[];
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UsersFromFileForm({
	open,
	setOpen,
	usersFromFile,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}: UsersFromFileFormProps) {
	const [materialTableLocalization, setMaterialTableLocalization] = useState({
		body: {
			emptyDataSourceMessage: 'Keine Nutzerinnen und Nutzer importiert.',
		},
		pagination: {
			labelDisplayedRows: '{from}-{to} von {count}',
			labelRowsSelect: 'Zeilen',
			labelRowsPerPage: 'Zeilen pro Seite:',
		},
	});
	const [selectedUsers, setSelectedUsers] = useState([] as User[]);

	const newUsersFromFile = usersFromFile.filter((x) => x.userId == null);
	const existingUsersFromFile = usersFromFile.filter(
		(x) => !newUsersFromFile.includes(x)
	);

	const handleClose = () => {
		setOpen(false);
	};

	function isSaveButtonEnabled() {
		return selectedUsers?.length > 0;
	}

	const uploadSelectedUsers = async () => {
		setOpen(false);
		let userInsertParametersList = [] as UserInsertParameters[];
		userInsertParametersList = selectedUsers.map((user) => {
			let userInsertParameters: UserInsertParameters = {
				user: {
					...user,
					username: getUserNameWithTenantSuffix(
						user.username,
						user.tenantIdentifier as string
					),
				},
				groups: [],
				initialPassword: 'tcc',
			};
			return userInsertParameters;
		});

		const client = getNetworkApi();
		try {
			await client.postUsers(userInsertParametersList);
			setAlertText(
				'Die ausgewählten Mitarbeiterdaten wurde erfolgreich hochgeladen'
			);
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Die Mitarbeiterdaten wurden nicht hochgeladen.');
			setShowErrorAlert(true);
		}
	};

	const newUserColumns: Column<User>[] = [
		{
			title: 'Nutzername',
			field: 'username',
			sorting: true,
			filtering: true,
			render: useCallback((user: User) => user.username, []),
		},
		{
			title: 'Vorname',
			field: 'firstName',
			sorting: true,
			filtering: true,
			render: useCallback((user: User) => user.firstName, []),
		},
		{
			title: 'Nachname',
			field: 'lastName',
			sorting: true,
			filtering: true,
			render: useCallback((user: User) => user.lastName, []),
		},
	];

	const existingUserColumns: Column<User>[] = [
		{
			title: 'Nutzername',
			field: 'username',
			sorting: true,
			filtering: true,
			render: useCallback((user: User) => user.username, []),
		},
		{
			title: 'Vorname',
			field: 'firstName',
			sorting: true,
			filtering: true,
			render: useCallback((user: User) => user.firstName, []),
		},
		{
			title: 'Nachname',
			field: 'lastName',
			sorting: true,
			filtering: true,
			render: useCallback((user: User) => user.lastName, []),
		},
	];

	const handleSelectionChange = (rows: User[]) => {
		setSelectedUsers(rows);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					maxWidth='md'>
					<DialogTitle>{'Importiere Mitarbeiter:innen hochladen:'}</DialogTitle>
					<DialogContent>
						<Grid container spacing={2} paddingTop={2}>
							<Grid item xs={12}>
								<MaterialTable
									localization={materialTableLocalization}
									style={{ borderRadius: '30px', color: grey[500] }}
									icons={MaterialTableIcons()}
									columns={newUserColumns}
									data={newUsersFromFile}
									components={{
										Container: (props) => <Paper {...props} elevation={3} />,
									}}
									options={{
										paging: true,
										showTitle: false,
										sorting: true,
										filtering: true,
										search: false,
										rowStyle: { fontSize: 16 },
										headerStyle: {
											position: 'sticky',
											top: 0,
											backgroundColor: 'white',
										},
										maxBodyHeight: viewportHeight(80),
										selection: true,
										showTextRowsSelected: false,
									}}
									onSelectionChange={handleSelectionChange}
								/>
							</Grid>
							{existingUsersFromFile.length > 0 && (
								<Grid item xs={12}>
									<MaterialTable
										localization={materialTableLocalization}
										style={{ borderRadius: '30px', color: grey[500] }}
										icons={MaterialTableIcons()}
										columns={existingUserColumns}
										data={existingUsersFromFile}
										components={{
											Container: (props) => <Paper {...props} elevation={3} />,
											// workaround to left align table title: https://github.com/mbrn/material-table/issues/1690
											Toolbar: (props) => {
												const propsCopy = { ...props };
												propsCopy.showTitle = false;
												return (
													<Grid container direction='row'>
														<Grid
															item
															xs={3}
															md={6}
															lg={12}
															marginBottom={-20}
															marginTop={2}>
															<DialogContentText>
																Diese importierten Nutzer sind schon vorhanden:
															</DialogContentText>
														</Grid>
														<Grid item xs={6}>
															<MTableToolbar {...propsCopy} />
														</Grid>
													</Grid>
												);
											},
										}}
										options={{
											paging: false,
											sorting: false,
											filtering: false,
											search: false,
											rowStyle: { fontSize: 16 },
											headerStyle: {
												position: 'sticky',
												top: 0,
												backgroundColor: 'white',
											},
											maxBodyHeight: viewportHeight(80),
											selection: false,
											showTextRowsSelected: false,
										}}
										onSelectionChange={handleSelectionChange}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} {'Abbrechen'}
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant='contained'
											onClick={uploadSelectedUsers}
											disabled={!isSaveButtonEnabled()}>
											{<Save />} Hochladen
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
