import { useCallback, useMemo } from 'react';
import MaterialTable, { Column } from '@material-table/core';

import { Tenant } from '../generated';
import React from 'react';
import { grey } from '@mui/material/colors';
import { Tooltip, Button, Paper } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { MaterialTableIcons } from './MaterialTableIcons';
import {
	formatDate,
	isAuthorizedForTenantAdminRole,
	isAuthorizedForUserMgmtRole,
	viewportHeight,
} from './format';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import GroupsIcon from '@mui/icons-material/Groups';

interface TenantsTableViewProps {
	tenantsList: Tenant[];
	handleClickOpenTenantForm: any;
	handleClickUsers: any;
	handleClickResidences: any;
	userRoleList: string[];
}

export default function TenantsTableView({
	tenantsList,
	handleClickOpenTenantForm,
	handleClickUsers,
	handleClickResidences,
	userRoleList,
}: TenantsTableViewProps) {
	const tenantIdentifier = tenantsList[0]?.tenantIdentifier;

	const isAuthorizedTenantAdmin: boolean = useMemo(() => {
		return isAuthorizedForTenantAdminRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	const isAuthorizedUserMgmt: boolean = useMemo(() => {
		return isAuthorizedForUserMgmtRole(userRoleList, tenantIdentifier);
	}, [userRoleList, tenantIdentifier]);

	const columns: Column<Tenant>[] = [
		{ title: 'Name', field: 'tenantIdentifier' },
		{ title: 'Beschreibung', field: 'description', width: '40%' },
		{ title: 'letzte Änderung durch', field: 'userLastChange' },
		{
			title: 'letzte Änderung am',
			field: 'lastChanged',
			defaultSort: 'asc',
			sorting: true,
			render: useCallback(
				(tenant: Tenant) => formatDate(tenant.lastChanged),
				[]
			),
		},
		{
			sorting: false,
			render: useCallback(
				(tenant: Tenant) => {
					return (
						<React.Fragment>
							<Tooltip
								title={isAuthorizedTenantAdmin ? 'Bearbeite Tenant' : ''}>
								<span>
									<Button
										disabled={!isAuthorizedTenantAdmin}
										onClick={() => handleClickOpenTenantForm(false, tenant)}>
										<Edit
											color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'}
										/>
									</Button>
								</span>
							</Tooltip>
							<Tooltip
								title={
									isAuthorizedTenantAdmin ? 'Wohnheime anzeigen/hinzufügen' : ''
								}>
								<span>
									<Button
										disabled={!isAuthorizedTenantAdmin}
										onClick={() => handleClickResidences(tenant)}>
										<OtherHousesIcon
											color={isAuthorizedTenantAdmin ? 'primary' : 'secondary'}
										/>
									</Button>
								</span>
							</Tooltip>
							<Tooltip
								title={
									isAuthorizedUserMgmt ? 'Nutzer anzeigen/hinzufügen' : ''
								}>
								<span>
									<Button
										disabled={!isAuthorizedUserMgmt}
										onClick={() => handleClickUsers(tenant)}>
										<GroupsIcon
											color={isAuthorizedUserMgmt ? 'primary' : 'secondary'}
										/>
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[isAuthorizedTenantAdmin]
			),
		},
	];

	return (
		<MaterialTable
			localization={{
				body: {
					emptyDataSourceMessage: 'Keine Betreiber hinterlegt.',
				},
			}}
			style={{
				borderRadius: '30px',
				fontSize: '12',
				color: grey[500],
			}}
			icons={MaterialTableIcons()}
			columns={columns}
			data={tenantsList}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
			}}
			options={{
				paging: false,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: {
					fontSize: 'subtitle2',
					overflowWrap: 'break-word',
				},
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
